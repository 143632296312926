.row {
  background-color: var(--gray300);
}

.cell {
  height: 50px;
  padding: 0 0.5em;
  text-align: left;
  font-weight: normal;

  &.center,
  &.center * {
    text-align: center;
  }

  &.right,
  &.right * {
    text-align: right;
  }

  /* Only set to align with table's rows which display a left border on hover */
  &:first-child {
    border-left: 5px solid var(--gray300);
  }
}

.smallCell {
  width: 50px;
  max-width: 50px;
  box-sizing: border-box;
}

.exportCell {
  border-left: 2px solid var(--gray400);
}

.exportBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleWrapper {
  display: inline-flex;
  align-items: center;
  & .infoTooltip {
    margin-left: var(--spacing0500);
    font-size: var(--iconXS);
  }
}
